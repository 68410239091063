'use client';
import { useEffect, useState } from 'react';
import { HiX } from 'react-icons/hi';

import useGlobalState from 'src/hooks/useGlobalState';
import { createCookie, readCookie } from 'src/services/cookies';
import { UserRoleType } from 'src/types/users';

const COOKIE_MULTIPLE_ROLES_TOOLTIP = 'cookiemultiple_roles_tooltip';

export default function MultipleRolesTooltip() {
    const { user } = useGlobalState();

    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (user?.roles?.length) {
            const roles = user.roles.filter(
                (r) => ![UserRoleType.FORTAT, UserRoleType.EMAIL_MANAGER].find((ur) => ur === r),
            );
            setVisible(roles.length > 1 && !readCookie(COOKIE_MULTIPLE_ROLES_TOOLTIP));
        }
    }, [user]);

    return (
        visible && (
            <div role="tooltip" className="absolute bottom-1 right-0 z-[1000]" onClick={(e) => e.stopPropagation()}>
                <div className="border-b-primary absolute right-5 h-0 w-0 border-x-4 border-b-4 border-x-transparent text-white lg:right-16" />
                <div
                    className={`bg-primary absolute right-0 top-1 z-10 w-64 p-2 pt-6 text-center text-sm text-white lg:pt-4`}>
                    <HiX
                        className="absolute right-1 top-2 text-xl lg:text-base"
                        role="button"
                        onClick={(e) => {
                            createCookie(COOKIE_MULTIPLE_ROLES_TOOLTIP, 'true', 365);
                            setVisible(false);
                            e.stopPropagation();
                        }}
                    />
                    Pentru a vedea buletinele de pe celelalte roluri ale tale apasă acest buton
                </div>
            </div>
        )
    );
}
