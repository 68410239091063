'use client';

import Link from 'next/link';
import { BiExit } from 'react-icons/bi';
import { HiOutlineBeaker, HiOutlineCog, HiX } from 'react-icons/hi';

import { ImpersonateMenuItem } from './ImpersonateMenuItem';
import { SwitchRoleMenuItems, titleForRole } from './SwitchRoleMenuItems';
import { RiUser3Fill } from 'react-icons/ri';
import { myAccountUrl } from 'src/services/route-utils';
import { UserType } from 'src/hooks/useGlobalState';

type Props = {
    user: UserType;
    closeAccount: () => void;
    closeAllMenus: () => void;
};

export default function UserMenu({ user, closeAccount, closeAllMenus }: Props) {
    const MenuLink = (props) => <Link onClick={() => closeAllMenus()} {...props} />;

    return (
        <nav className="top-nav-sm-height h-screen-nav-sm-dynamic text-dark-green fixed left-0 right-0 z-40 min-w-[280px] bg-white font-normal drop-shadow-lg md:absolute md:bottom-auto md:left-auto md:right-4 md:top-auto md:mt-4 md:h-auto md:text-base">
            <div className="bg-light-grey relative cursor-default py-4 md:hidden">
                <HiX className="absolute right-4 top-4 cursor-pointer" onClick={closeAccount} title="Închide" />
            </div>
            <div className="border-dark-green/25 bg-light-grey cursor-default border-b p-4 md:px-6">
                <div className={`${user.first_name ? 'font-display text-3xl md:text-2xl' : ''}`}>
                    {user.first_name || user.email}
                </div>
                {user.settings?.last_role && (
                    <div className="text-primary text-xs">{titleForRole(user.settings.last_role)}</div>
                )}
            </div>
            <ul className="lg:text-sm">
                <li className="border-dark-green/25 border-b">
                    <MenuLink
                        href={myAccountUrl('/')}
                        className="hover:bg-light-green block p-4 md:px-6"
                        aria-label="Rezultate">
                        <HiOutlineBeaker title="Rezultate" className="text-primary mr-2 inline-block md:text-base" />
                        Rezultate
                    </MenuLink>
                </li>
                <SwitchRoleMenuItems closeAllMenus={closeAllMenus} />
                <li className="border-dark-green/25 border-b">
                    <MenuLink
                        href={myAccountUrl('/setari')}
                        className="hover:bg-light-green block p-4 md:px-6"
                        aria-label="Setări">
                        <HiOutlineCog title="Setări" className="text-primary mr-2 inline-block md:text-base" />
                        Setări cont
                    </MenuLink>
                </li>
                <li className="border-dark-green/25 border-b md:border-0">
                    <MenuLink
                        href={myAccountUrl('/logout')}
                        className="hover:bg-light-green block p-4 md:px-6"
                        aria-label="Ieși din cont">
                        <BiExit title="Logout" className="text-primary mr-2 inline-block md:text-base" />
                        Ieși din cont
                    </MenuLink>
                </li>
                <ImpersonateMenuItem closeAllMenus={closeAllMenus} />
            </ul>
        </nav>
    );
}
